<template>
  <ion-page>
    <ion-content id="main-content" :fullscreen="true">
      <div class="container">
        <h1>Forgot Password?</h1>
        <span class="subtitle"
          >Enter the email address associated with your account
          and we’ll send a link to reset your password.</span
        >
        <Input label="Email" />
        <Button text="SEND PASSWORD RESET LINK" class="input" variant="primary-bold"/>
      </div>
    </ion-content>

    <Footer />
  </ion-page>
</template>

<script>
  import { IonContent, IonPage } from '@ionic/vue';

  import Footer from '@/components/layout/Footer';
  import Input from '@/components/shared/inputs/Input.vue';
  import Button from '@/components/shared/buttons/Button.vue';

  export default {
    components: {
      IonContent,
      IonPage,
      Footer,
      Input,
      Button,
    },
  };
</script>

<style scoped lang="scss">
  ion-content {

    .container {
      margin-top: -5%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        margin-top: 60px;
        margin-bottom: 24px;
      }
      .subtitle {
        width: 467px;
      }
      .input{
        margin-top: 45px;
      }
      
    }
  }
</style>
